import { Col, Container, Image, Row } from "react-bootstrap";
import BreadCrumb from "../components/Breadcrumb";
import WithhisSeniorOfficersof6Kumaon from '../assets/WithhisSeniorOfficersof6Kumaon.png'
import './../scss/InnerPage.scss';
import TrustIntent from "../components/HomePage/TrustIntent";
import GalleryComponent from "../components/Gallery/GalleryComponent";
export default function AboutUs() {
    return(
        <>
          <BreadCrumb title="About Major Suresh Suri" />
          <section className="aboutus-page pt-80 pb-80">
            <Container>
              <Row>
                <Col md="12" lg="12" sm="12" xs="12">
                  <div className="about-us-image">
                    <Image src={WithhisSeniorOfficersof6Kumaon} alt="WithhisSeniorOfficersof6Kumaon" title="WithhisSeniorOfficersof6Kumaon" className="w-100" />
                  </div>
                  <div className="about-us-content-block mt-5">
                    <p>Major Suresh Suri belonged to Hyderabad and was born on 05th October 1979. Son of an IAF veteran S V Subba Rao and Mrs S Girija Kumary, Rtd PRT from KVS. Maj Suresh was the eldest of three siblings. His younger sister Surekha, who worked as a banker and is now a Lecturer and younger brother Sunil Suri is an IT professional working in Hyderabad.</p>
                    <p>Major Suresh Suri joined the Army in December 2001 and was commissioned into the Kumaon Regiment, an infantry regiment known for its gallant soldiers and numerous battle exploits.</p>
                    <p> During 2004-05 Major Suresh along with his unit successfully completed their tenure at Siachen Glacier.</p>
                    <p>When 6 Kumaon was posted at Delhi in 2007, Maj Suresh was assigned as Special Officer to Former President Shri Dr A.P.J. Abdul Kalam when he completed his tenure as 11th President of India.</p>
                    <p> Major Suresh got married on 20th August 2008 to Pallavi but she could not join him as he was serving in a field area in J & K during that time.</p>
                    <p>After serving for few years with his parent unit, Major Suresh was later deputed to serve with 13 RR battalion in 2008, deployed in J & K for counter insurgency operations.</p>
                  </div>
                  <div className="about-us-content-block mt-5">
                    <h4 className="aboutus-title">Bandipora Operation: 22 Sep 2009</h4>
                    <p>On 22 Sep 2009, Maj Suresh Suri’s unit had received credible intelligence reports about the presence of terrorists in a village in Bandipora district. A decision was taken to launch a search and destroy operation to nab the terrorists under the leadership of Maj Suresh. As planned, Maj Suresh along with his troops swung into action and cordoned off the suspected house.</p>
                    <p>After carrying out quick appreciation of the situation, Major Suresh Suri along with three other soldiers entered the house to search for the hideout. On the second floor of house, Maj Suresh found some suspicious loose planks of wood on the roof, which indicated the possibility of a hideout. Maj Suresh decided to scale the roof with another soldier to achieve surprise. However, they came under heavy fire from the terrorists and got seriously injured. Maj Suresh despite being injured retaliated swiftly from his AK-47 gun and lobbed a hand grenade, killing one terrorist and injuring the other. The second terrorist though injured took cover in the hideout and started firing indiscriminately, causing injuries to other personnel of the search party. Maj Suresh continued to direct fire to ensure the safe evacuation of his injured comrades. However, Maj Suresh later succumbed to his injuries and was martyred.</p>
                    <p>Maj Suresh Suri was awarded the nation’s second highest peace time gallantry award, “Kirti Chakra” for his outstanding courage, leadership, and supreme sacrifice.</p>
                  </div>
                  <div className="about-us-content-block mt-5">
                    <h4 className="aboutus-title">Citation for the Kirti Chakra awarded to him reads</h4>
                    <p>On 22 Sep 2009, Maj Suresh Suri was part of inner cordon around a suspected house. After carrying out quick appreciation of the situation, Major Suresh Suri along with three other ranks entered the house to search for the hideout. On the second floor of house the officer found some suspicious loose planks of wood on the roof where the hideout was expected. The officer scaled the roof with his buddy to achieve surprise.</p>
                    <p>They however came under heavy fire from the terrorists and got seriously injured. The officer despite being injured retaliated with fire from his Rifle AK-47 and lobbed a hand grenade, killing one terrorist and injuring the other. The second terrorist took cover in the hide out and started firing indiscriminately, causing injuries to other personnel of the search party. The officer ensured the safe evacuation of injured comrades. In the ensuing fire fight the officer was grievously injured and later succumbed to his injuries.</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <TrustIntent />
          <GalleryComponent />
        </>
    )
}