import { Col, Container, Row } from "react-bootstrap";

export default function BreadCrumb(props) {
    return(
        <section className="breadcrumb">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                      <h4 className="page-title sita-font">{props.title}</h4>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}