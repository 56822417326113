import { Col, Container, Row } from "react-bootstrap";
import './Footer.scss';
export default function Footer() {
    return(
        <section className="footer">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h4 className="footer-title">Copyright © 2010-2023 Major Suresh Suri Foundation. All rights reserved.</h4>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}