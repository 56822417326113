import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import MailIcon from '../../assets/mail.png'
import PhoneIcon from '../../assets/phone.png'
import './Header.scss';
import Facebbok from '../../assets/facebook.png'
import Twitter from '../../assets/twitter.png'
import Instagram from '../../assets/instagram.png'
import Logo from '../../assets/Logo.svg'
import { Link } from "react-router-dom";
import { useState } from "react";
export default function Header() {
    const [expanded, setexpanded] = useState(false);
    return(
        <>
           <section className="topbar">
            <Container>
                <Row>
                    <Col md="4" lg="4" sm="4" xs="8" className="self-center">
                        <div className="topbar-block">
                            <div className="topbar-icon">
                                <Image src={MailIcon} alt="Mail Icon" title="Mail Icon" />
                                <span>info@majorsureshsuri.org</span>
                            </div>
                            <div className="topbar-icon" onClick={()=>window.open('tel:+919949077450','_blank')}>
                                <Image src={PhoneIcon} alt="PhoneIcon" title="PhoneIcon" />
                                <span>+91 99490 77450</span>
                            </div>
                        </div>
                    </Col>
                    <Col md="8" lg="8" sm="8" xs="4" className="self-center">
                        <ul className="social-icons">
                            <Image src={Facebbok} alt="Facebook" title="Facebook" />
                            <Image src={Instagram} alt="Instagram" title="Instagram" />
                            <a href="https://twitter.com/majorsureshsuri?lang=en" target="_blank" rel="noreferrer"><Image src={Twitter} alt="Twitter" title="Twitter" /></a>
                        </ul>
                    </Col>
                </Row>
            </Container>
           </section>
           <section className="menu">
           <Navbar expand="lg" className="bg-transparent" expanded={expanded}>
            <Container>
                <Link onClick={()=>{setexpanded(false)}} to={process.env.PUBLIC_URL+'/'} className="navbar-brand"  href="#">
                    <Image src={Logo} alt="Logo" title="Logo" />
                    <span className="logo-text">MAJOR <br/>SURESH SURI <br/>FOUNDATION</span>
                </Link>
                <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setexpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="navbarScroll" className="justify-content-end">
                <Nav
                    className="ml-auto my-2 my-lg-0"
                    navbarScroll
                >
                    <Link to={process.env.PUBLIC_URL+'/support'} onClick={()=>{setexpanded(false)}} className="donate-cause-btn nav-link">DONATE FOR A CAUSE</Link>
                    <Link to={process.env.PUBLIC_URL+'/'} onClick={()=>{setexpanded(false)}} className="nav-link">Home</Link>
                    <Link to={process.env.PUBLIC_URL+'/about'} onClick={()=>{setexpanded(false)}} className="nav-link">About Major Suresh Suri</Link>
                    <Link to={process.env.PUBLIC_URL+'/'} onClick={()=>{setexpanded(false)}} className="nav-link">Intent of the Foundation</Link>
                    <Link to={process.env.PUBLIC_URL+'/activites'} onClick={()=>{setexpanded(false)}} className="nav-link">Activities</Link>
                    <Link to={process.env.PUBLIC_URL+'/gallery'} onClick={()=>{setexpanded(false)}} className="nav-link">Gallery</Link>
                    <Link to={process.env.PUBLIC_URL+'/contact-us'} onClick={()=>{setexpanded(false)}} className="nav-link">Contact Us</Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
           </section>
        </>
    )
}