import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import DistributingBreakfast from '../../assets/gallery/event/Food_Distribution.mp4'
import DistributingBreakfast2 from '../../assets/gallery/event/Food_Distribution_2.png'
import DistributingBreakfast3 from '../../assets/gallery/event/Food_Distribution_3.png'
import DistributingBreakfast4 from '../../assets/gallery/event/Food_Distribution_4.png'
import DistributingBreakfast5 from '../../assets/gallery/event/Food_Distribution_5.png'
import DistributingBreakfast6 from '../../assets/gallery/event/Food_Distrubtion_6.png'
import DistributingBreakfast7 from '../../assets/gallery/event/Food_Distribution_7.png'
import DistributingBreakfast8 from '../../assets/gallery/event/Food_Distribution_8.png'
import DistributingBreakfast9 from '../../assets/gallery/event/Food_Distribution-9.png'
export default function GalleryComponent() {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2.5,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ],
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };
    return(
        <section className="gallery-component pt-80 pb-80">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12" className="text-center">
                        <h4 className="aims-title sita-font">Gallery</h4>
                    </Col>
                </Row>
                <Row className="mt-5">
                <Slider {...settings}>
                    <div>
                        <p className="gallery-image">
                        <video src={DistributingBreakfast} type="video/mp4" controls poster="Food_Distribution_PosterImage.png" width="100%" height="100%">
                            Sorry, your browser doesn't support HTML5 video tag.
                        </video>
                        <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                        </p>
                    </div>
                    <div>
                        <p className="gallery-image">
                        <Image src={DistributingBreakfast2} alt="DistributingBreakfast2" title="DistributingBreakfast2" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                        </p>
                    </div>
                    <div>
                        <p className="gallery-image">
                        <Image src={DistributingBreakfast3} alt="DistributingBreakfast3" title="DistributingBreakfast3" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                        </p>
                    </div>
                    <div>
                        <p className="gallery-image">
                        <Image src={DistributingBreakfast4} alt="DistributingBreakfast4" title="DistributingBreakfast4" className="w-100" />
                                <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                        </p>
                    </div>
                    <div>
                        <p className="gallery-image">
                            <Image src={DistributingBreakfast5} alt="DistributingBreakfast5" title="DistributingBreakfast5" className="w-100" />
                            <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                        </p>
                    </div>
                    <div>
                    <p className="gallery-image">
                        <Image src={DistributingBreakfast6} alt="DistributingBreakfast6" title="DistributingBreakfast6" className="w-100" />
                        <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                      </p>
                    </div>
                    <div>
                    <p className="gallery-image">
                        <Image src={DistributingBreakfast7} alt="DistributingBreakfast7" title="DistributingBreakfast7" className="w-100" />
                        <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                      </p>
                    </div>
                    <div>
                    <p className="gallery-image">
                        <Image src={DistributingBreakfast8} alt="DistributingBreakfast8" title="DistributingBreakfast8" className="w-100" />
                        <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                      </p>
                    </div>
                    <div>
                    <p className="gallery-image">
                        <Image src={DistributingBreakfast9} alt="DistributingBreakfast9" title="DistributingBreakfast9" className="w-100" />
                        <h4 className="gallery-block-title">Distributing breakfast at Sarojini Devi Hospital, Mehdipatnam, Hyderabad</h4>
                      </p>
                    </div>
                </Slider>
                </Row>
                <div className="button mt-5 text-center">
                    <Link to={process.env.PUBLIC_URL+'/gallery'} className="btn btn-secondary text-subtitle2 mtop-30">VIEW MORE</Link>
                </div>
            </Container>
        </section>
    )
}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "white" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "white" }}
        onClick={onClick}
      />
    );
  }