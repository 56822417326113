/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Container, Row, Image, Form, Button } from "react-bootstrap";
import BreadCrumb from "../components/Breadcrumb";
import FacebookFooter from '../assets/Icon awesome-facebook-f.svg'
import Instagram from '../assets/Icon awesome-instagram.svg'
import twitter from '../assets/Icon awesome-twitter.svg'
import { useState } from "react";
import { ContactSubmission } from "../actions/Actions";
export default function ContactUs() {
    const [userData,setUserData] = useState({name:'',email:'',mobileNo:'',message:''})
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        }
        else {
            event.preventDefault();
            ContactSubmission(userData).then((res)=>{
                if(res.data.success===1) {
                   alert('Thanks for contacting us.our team will get back to you soon.');
                   window.location.reload();
                } else {
                    alert(res.data.message);
                }
            }).catch((err)=>{
                alert('Something went wrong please try again');
            })
        }
        setValidated(true);
    };
    return(
        <>
           <BreadCrumb title="Contact Us" />
           <section className="contact-us pt-80 pb-80">
                <Container>
                    <Row>
                        <Col md="6" lg="6" sm="6" xs="12">
                            <div className="contact-us-block">
                                <h4 className="contactus-title">Get In Touch With Us</h4>
                                <p>If you wish to know more about the Trust and contribute in any way to our Superheroes, please feel free to get in touch with us.</p>
                                <div className="contact-us-icons">
                                    <a href=""><Image src={Instagram} alt="Instagram Footer" title="Instagram Footer" /></a>
                                    <a href=""><Image src={FacebookFooter} alt="Facebook Footer" title="Facebook Footer" /></a>
                                    <a href="https://twitter.com/majorsureshsuri?lang=en" target="_blank" rel="noreferrer"><Image src={twitter} alt="twitter Footer" title="twitter Footer" /></a>
                                </div>
                            </div>
                        </Col>
                        <Col md="6" lg="6" sm="6" xs="12" className="mobile-space-top">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" placeholder="Full Name" onChange={e=>setUserData({...userData,name:e.target.value})} value={userData.name} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter name...
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" lg="6" sm="6" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="email" placeholder="Email Address" onChange={e=>setUserData({...userData,email:e.target.value})} value={userData.email} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Email address...
                                                </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md="12" lg="12" sm="12" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="number" placeholder="Phone Number" onChange={e=>setUserData({...userData,mobileNo:e.target.value})} value={userData.mobileNo} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter Valid Mobile Number...
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md="12" lg="12" sm="12" xs="12">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control as="textarea" placeholder="Write Message" rows={7} onChange={e=>setUserData({...userData,message:e.target.value})} value={userData.message} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="button text-center mt-3">
                                    <Button className="btn btn-secondary" type="submit">Submit</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="contactusinfo-details contactusinfo-details1">
                                <h3 className="contact-info-title">About Major Suresh</h3>
                                <p>Major Suresh Suri belonged to Hyderabad and was born on 05th October 1979</p>
                            </div>
                        </Col>
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="contactusinfo-details contactusinfo-details2">
                                <h3 className="contact-info-title">Address</h3>
                                <p>Hemachandra Heights, Sreeram Nagar Colony, Masab Tank, Hyderabad, 500028</p>
                            </div>
                        </Col>
                        <Col md="4" lg="4" sm="4" xs="12">
                            <div className="contactusinfo-details contactusinfo-details3">
                                <h3 className="contact-info-title">Contact</h3>
                                <p>+91 99490 77450 <br/>+91 8008 544662</p>
                                <p>info@majorsureshsuri.org</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
           </section>
        </>
    )
}