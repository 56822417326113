import Banner from "../components/Banner/Banner";
import GalleryComponent from "../components/Gallery/GalleryComponent";
import ActivitiesComponent from "../components/HomePage/ActivitiesComponent";
import AIMS from "../components/HomePage/Aims";
import Coverage from "../components/HomePage/Courage";
import Information from "../components/HomePage/Information";
import TrustIntent from "../components/HomePage/TrustIntent";
// import '../scss/Responsive.scss';
export default function HomePage() {
    return(
        <>
           <Banner />
           <Coverage />
           <Information />
           <AIMS />
           <TrustIntent />
           <ActivitiesComponent />
           <GalleryComponent />
        </>
    )
}