import { Col, Container, Row } from "react-bootstrap";
import './Banner.scss';
export default function Banner() {
    return(
        <section className="banner">
            <Container>
                <Row className="justify-content-center">
                    <Col md="4" lg="4" sm="4">
                        <div className="banner-content">
                            <h3 className="sita-font">Commemorating <br/>the life of an Unsung Hero <br/> <span className="orange">Major</span> Suresh <span className="positive">Suri</span></h3>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}