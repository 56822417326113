import { Col, Container, Row,Image } from "react-bootstrap";
import BreadCrumb from "../components/Breadcrumb";
import DonationtoSriVidyaSaraswathiShaniTempleWargal from '../assets/activites/Vidya-saraswathi-swamy-temple.png'
import DonationtoVeda from '../assets/activites/DonationstoVedaPathashaala.png'
import HelpingFamilesToNeed from '../assets/activites/helping-familes-to-need.png'
import AspiringDreamFullFilled from '../assets/activites/some-aspiring-dreams-fullfilled.png'
import FuelEducation from '../assets/activites/fueling-educational.png'
import AIDHyderabadSociety from '../assets/activites/hyderabad-children-aid-society.png'
import TrustIntent from "../components/HomePage/TrustIntent";
import GalleryComponent from "../components/Gallery/GalleryComponent";
export default function Activities() {
    return(
        <>
           <BreadCrumb title="Activities" />
           <section className="activities-display pt-80 pb-80">
            <Container>
                <Row>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="news-card card-space-right">
                            <div className="news-image">
                                <Image src={DonationtoSriVidyaSaraswathiShaniTempleWargal} alt="DonationtoSriVidyaSaraswathiShaniTempleWargal" title="DonationtoSriVidyaSaraswathiShaniTempleWargal" className="w-100" />
                            </div>
                            <div className="news-content">
                                <h4 className="news-title sita-font">Sri Vidya Saraswathi Shani Temple Wargal</h4>
                                <h6 className="news-date">24 SEP, 2023</h6>
                                <p>Smt Suri Girija Kumary M/O of Major Suresh donated Rs 25,000/- at Sri Vidya Saraswathi Shani Temple Wargal. The donated amount will be part of fund to buy books for students of Vedha Patashala. She has been an inspiration for Major Suresh Suri Foundation and doing this work since 2010.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="news-card card-space-left">
                            <div className="news-image">
                                <Image src={DonationtoVeda} alt="DonationtoVeda" title="DonationtoVeda" className="w-100" />
                            </div>
                            <div className="news-content">
                                <h4 className="news-title sita-font">Donations to Veda Pathashaala</h4>
                                <h6 className="news-date">24 SEP, 2023</h6>
                                <p>MSSF worked along with Mrs Achanta Bhavani and Mr Achanta Udaya Bhaskara Rao, Parents of Achanta Vinod (school friend of my brother) by donating Rs 25,000/- at Sri Vidya Saraswathi Shani Temple Wargal. The donated amount will be part of fund to buy books for students of Vedha Patashala</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="news-card card-space-right">
                            <div className="news-image">
                                <Image src={HelpingFamilesToNeed} alt="HelpingFamilesToNeed" title="HelpingFamilesToNeed" className="w-100" />
                            </div>
                            <div className="news-content">
                                <h4 className="news-title sita-font">Helping families to meet ends!</h4>
                                <h6 className="news-date">01 AUG, 2023</h6>
                                <p>On 1st August 2023, MSSF started working along with Mr Sreedhar Mangapathy & his family members who will be helping Smt Neema Devi M/O Late Naik Deepak Singh Dasila who made supreme sacrifice of his life fighting against terrorists on 18th June 2020 with an amount of Rs 10,000/- per month to meet her expenses.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="news-card card-space-left">
                            <div className="news-image">
                                <Image src={AspiringDreamFullFilled} alt="AspiringDreamFullFilled" title="AspiringDreamFullFilled" className="w-100" />
                            </div>
                            <div className="news-content">
                                <h4 className="news-title sita-font">Some aspiring dreams fulfilled!</h4>
                                <h6 className="news-date">28 JULY, 2023</h6>
                                <p>MSSF with the help of K V N Raghu Kumar and Anusha Suri, donated Rs 30,000/- to Mohit Kumar Singh who has completed his degree and is currently preparing for bank PO exams.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="news-card card-space-right">
                            <div className="news-image">
                                <Image src={FuelEducation} alt="FuelEducation" title="FuelEducation" className="w-100" />
                            </div>
                            <div className="news-content">
                                <h4 className="news-title sita-font">Fueling Educational!</h4>
                                <h6 className="news-date">04 AUG, 2022</h6>
                                <p>Major Suresh Suri Foundation had contributed Rs 30,000/- as part of education expenses for daughter of Late Sepoy Pradeep Chandra Pandey. Mrs Manisha Pandey is staying in Ranikhet war widow hostel along with her daughter who is studying class 9</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="news-card card-space-left">
                            <div className="news-image">
                                <Image src={AIDHyderabadSociety} alt="AIDHyderabadSociety" title="AIDHyderabadSociety" className="w-100" />
                            </div>
                            <div className="news-content">
                                <h4 className="news-title sita-font">The Hyderabad Children Aid Society</h4>
                                <h6 className="news-date">04 APR, 2022</h6>
                                <p>Majore Suresh Suri Foundation donated clothes to "The Hyderabad Children Aid Society" at Nanalnagar, Medhipatnam started in 1950 by Smt Tehminabai Dhage and Shri Venkat Krishnaji Dhage</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
           </section>
           <TrustIntent />
           <GalleryComponent />
        </>
    )
}