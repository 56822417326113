import { Col, Container, Image, Row } from "react-bootstrap";
import BreadCrumb from "../components/Breadcrumb";
import GalleryComponent from "../components/Gallery/GalleryComponent";
import TrustIntent from "../components/HomePage/TrustIntent";
import ArrowCircleLeft from '../assets/arrow-right-drop.svg'
export default function Support() {
    return(
        <>
           <BreadCrumb title="Why Support Major Suresh Suri Foundation?" />
           <section className="about-support pt-80 pb-80">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <div className="about-us-content-block">
                            <h4 className="aboutus-title">Aims and Objectives of the Trust</h4>
                            <p>
                                <span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>Provide financial aid in the form cash or kind for education of siblings, children of Martyrs / Ex Servicemen of 6 Kumaon and Kumaon Regiment. 1st preference will be given to children of OR and Martyr’s.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>Provide financial aid in the form of cash or kind for medical expenses to the parents of Martyrs / Ex Servicemen of 6 Kumaon and Kumaon Regiment. 1st preference will be given to children of OR and Martyr’s.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>Build memorial in schools of Martyrs which shall inspire and infuse patriotic values in students to take the path of serving the nation through Indian Defense Services.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>Provide financial aid in the form of cash or kind to the schools run within Defense Units / Brigades.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>Provide financial aid in the form cash or kind during any type of medical treatment of Men in Service / Ex Servicemen / children or immediate family members. 1st preference will be given to children of OR and Martyr’s.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>Provide financial aid in the form of cash or kind to Military Hospitals which shall be used in purchase of medical devices / equipment / stationary for associated mess / maintenance of poly clinics.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span> Provide financial aid in form of cash to family of Ex Servicemen whose house has been damaged in natural calamity.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>To provide free food/provisions/water / cloths/ financial or any kind of help to the old age homes/orphanages/ children homes/Hospitals.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>The Trust will not carry out any activities with the intention of earning profit and will perform with service motive only.</span></p>
                            <p><span className="icon-arrow"><Image src={ArrowCircleLeft} alt="ArrowCircleLeft" title="ArrowCircleLeft" /></span>
                                <span>No activities of the Trust will be carried out outside India.</span></p>
                        </div>
                        <div className="bank-details">
                            <h4 className="bank-details-title">Bank Details:</h4>
                            <Row>
                                <Col md="7" lg="7" sm="7" xs="12" className="border-bank-right q-pr-md">
                                    <div className="bank-details-content">
                                        <h3 className="width-28">Account Number</h3>
                                        <h3 className="width-72">: 120025958124</h3>
                                    </div>
                                    <div className="bank-details-content">
                                        <h3 className="width-28">Account Name</h3>
                                        <h3 className="width-72">: MAJOR SURESH SURI FOUNDATION</h3>
                                    </div>
                                    <div className="bank-details-content">
                                        <h3 className="width-28">Bank Name</h3>
                                        <h3 className="width-72">: Canara Bank</h3>
                                    </div>
                                    <div className="bank-details-content">
                                        <h3 className="width-28">Branch Address</h3>
                                        <h3 className="width-72">: Ahmednagar, Hyderabad, Telangana</h3>
                                    </div>
                                </Col>
                                <Col md="5" lg="5" sm="5" xs="12" className="q-pl-md">
                                    <div className="bank-details-content">
                                        <h3 className="width-43">IFSC Code</h3>
                                        <h3 className="width-67">: CNRB001622</h3>
                                    </div>
                                    <div className="bank-details-content">
                                        <h3 className="width-43">MICR</h3>
                                        <h3 className="width-67">: 500015004</h3>
                                    </div>
                                    <div className="bank-details-content">
                                        <h3 className="width-43">Foreign Exchange/ SWIFT Code</h3>
                                        <h3 className="width-67">: CNRBINBBFD</h3>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
           </section>
           <TrustIntent />
           <GalleryComponent />
        </>
    )
}