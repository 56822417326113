import { Col, Container, Image, Row } from "react-bootstrap";
import DonationtoSriVidyaSaraswathiShaniTempleWargal from '../../assets/DonationtoSriVidyaSaraswathiShaniTempleWargal.png'
import DonationtoVeda from '../../assets/donation-to-veda-patasala.png'
import HelpingFamiliesToMeetEnd from '../../assets/helping-families-tomeetend.png'
import HydChildrenAidSociety from '../../assets/hyderabadchildrenaidsociety.png'
import { Link } from "react-router-dom";
export default function ActivitiesComponent() {
    return(
        <section className="activites pt-80 pb-80">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12" className="text-center">
                    <h4 className="aims-title sita-font">ACTIVITIES DONE TILL DATE</h4>
                        <h6 className="sub-caption">NEWS AND UPDATES</h6>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md="6" lg="6" sm="6" xs="12">
                        <div className="news-card column-space-right">
                            <div className="news-image">
                                <Image src={DonationtoSriVidyaSaraswathiShaniTempleWargal} alt="DonationtoSriVidyaSaraswathiShaniTempleWargal" title="DonationtoSriVidyaSaraswathiShaniTempleWargal" className="w-100" />
                            </div>
                            <div className="news-content">
                                <h4 className="news-title sita-font">Donation to Sri Vidya Saraswathi Shani Temple Wargal</h4>
                                <h6 className="news-date">24 SEP, 2023</h6>
                                <p>On 24th September 2023 Smt Suri Girija Kumary M/O of Major Suresh donated Rs 25,000/- at Sri Vidya Saraswathi Shani Temple Wargal. The donated amount will be part of fund to buy books for students of Vedha Patashala. She has been an inspiration for Major Suresh Suri Foundation and doing this work since 2010</p>
                                <div className="button mtop-3o">
                                    <Link to={process.env.PUBLIC_URL+'/activites'} className="btn btn-secondary text-subtitle2">READ MORE</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6" sm="6" xs="12" className="past-news-card-bg">
                        <div className="past-news-card">
                            <div className="past-news">
                                <Row>
                                    <Col md="3" lg="3" sm="3" xs="12">
                                        <div className="past-news-icon">
                                            <Image src={DonationtoVeda} alt="DonationtoVeda" title="DonationtoVeda" className="w-100" />
                                        </div>
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <div className="past-news-content">
                                        <h6 className="news-date">24 SEP, 2023</h6>
                                        <h4 className="past-news-title">Donation to Vedha Patashala, Wargal</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="past-news">
                                <Row>
                                <Col md="3" lg="3" sm="3" xs="12">
                                        <div className="past-news-icon">
                                            <Image src={HelpingFamiliesToMeetEnd} alt="HelpingFamiliesToMeetEnd" title="HelpingFamiliesToMeetEnd" className="w-100" />
                                        </div>
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <div className="past-news-content">
                                        <h6 className="news-date">01 AUG, 2023</h6>
                                        <h4 className="past-news-title">Helping families to meet ends!</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="past-news">
                                <Row>
                                <Col md="3" lg="3" sm="3" xs="12">
                                        <div className="past-news-icon">
                                            <Image src={HydChildrenAidSociety} alt="HydChildrenAidSociety" title="HydChildrenAidSociety" className="w-100" />
                                        </div>
                                    </Col>
                                    <Col md="9" lg="9" sm="9" xs="12">
                                        <div className="past-news-content">
                                        <h6 className="news-date">04 APR, 2022</h6>
                                        <h4 className="past-news-title">The Hyderabad Children Aid Society</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}