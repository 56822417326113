import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/App.scss';
import reportWebVitals from './reportWebVitals';
import HomePage from './views/HomePage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import AboutUs from './views/AboutUs';
import ContactUs from './views/ContactUs';
import Support from './views/Support';
import Gallery from './views/Gallery';
import Activities from './views/Activities';
// import './scss/Responsive.scss';
const root = ReactDOM.createRoot(document.getElementById('root'));
export const Page = (props) => {
  useEffect(()=>{
    console.log(window.location.pathname)
  },[])
  
  return (
   <>
    <Router>
    <ScrollToTop />
      <Header />
      <Switch>
            <Route exact path="/" component={HomePage} />
            <Route  path='/about' component={AboutUs} />
            <Route path='/contact-us' component={ContactUs} />
            <Route path='/support' component={Support} />
            <Route path='/gallery' component={Gallery} />
            <Route path='/activites' component={Activities} />
          </Switch>
        <Footer />
      </Router>
    </>
  )
}
root.render(
  <React.StrictMode>
  <Page />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
