import { Container, Row,Col, Image } from "react-bootstrap";
import './HomePage.scss';
import CoverageImage from '../../assets/coverage-image.png'
export default function Coverage(){
    return(
        <section className="coverage">
            <Container>
                <Row>
                    <Col md="3" lg="3" sm="3" xs="12" className="self-center">
                        <div className="text-end">
                        <h4>Courage Beyond <br className="mobil-hide"/>Measure, Honoring<br className="mobil-hide"/> our Brave Hearts</h4>
                        </div>
                    </Col>
                    <Col md="9" lg="9" sm="9" xs="12" className="coverage-content self-center">
                        <Row>
                            <Col md="4" lg="4" sm="4" xs="12" className="self-center">
                                <Image src={CoverageImage} alt="CoverageImage" title="CoverageImage" className="w-100" />
                            </Col>
                            <Col md="8" lg="8" sm="8" xs="12" className="self-center">
                                <div className="coverage-content-cover no-padding-left">
                                    <p>An ode to all the courageous soldiers, because of whom we breathe safely.</p>
                                    <p>Let's all join hands in supporting the families of our Gallanted Heroes</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}