import { Col, Container, Image, Row } from "react-bootstrap";
import MajorSuriImage from '../../assets/MajorSureshSuri.png'
import RootsOfFoundation from '../../assets/BestNCCCadetatSchoolin7thClass.png'
import DuringNDADays from '../../assets/DuringNDADays.png'
import { Link } from "react-router-dom";
export default function Information() {
    return(
        <section className="information pt-80 pb-80">
            <Container>
                <Row>
                    <Col md="4" sm="4" lg="4" xs="12">
                        <div className="information-image-card">
                            <div className="icon">
                                <Image src={MajorSuriImage} alt="Major suri image" title="Major suri image" className="w-100" />
                            </div>
                            <div className="information-content">
                                <h5 className="information-content-title">Gallant Journey of Major Suresh Suri</h5>
                                <div className="button">
                                    <Link to={process.env.PUBLIC_URL+'/about'} className="btn btn-primary">READ MORE</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" sm="4" lg="4" xs="12" className="mobile-space-top">
                        <div className="information-image-card-2">
                            <div className="icon">
                                <Image src={RootsOfFoundation} alt="RootsOfFoundation" title="RootsOfFoundation" className="w-100" />
                            </div>
                            <div className="information-content">
                                <h5 className="information-content-title">The Roots and Intent of the Foundation</h5>
                                <div className="button">
                                    <Link to={process.env.PUBLIC_URL+'/support'} className="btn btn-primary">READ MORE</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="4" sm="4" lg="4" xs="12" className="mobile-space-top">
                        <div className="information-image-card-3">
                            <div className="icon">
                                <Image src={DuringNDADays} alt="DuringNDADays" title="DuringNDADays" className="w-100" />
                            </div>
                            <div className="information-content">
                                <h5 className="information-content-title">Contribution for Bravehearts</h5>
                                <div className="button">
                                    <Link to={process.env.PUBLIC_URL+'/activites'} className="btn btn-primary">READ MORE</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}