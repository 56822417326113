import { Col, Container, Image, Row } from "react-bootstrap";
import Diet from '../../assets/icons/diet.png'
import Education from '../../assets/icons/education.png'
import HealthCare from '../../assets/icons/healthcare.png'
import Rupee from '../../assets/icons/rupee.png'
import { Link } from "react-router-dom";
export default function AIMS() {
    return(
        <section className="aims pb-80">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12" className="text-center">
                        <h4 className="aims-title sita-font">AIMS AND OBJECTIVES OF <br className="mobil-hide"/>THE TRUST</h4>
                        <h6 className="sub-caption">HELP FOR A CAUSE</h6>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col md="3" lg="3" sm="3" xs="12">
                        <div className="aim-card">
                            <div className="text-center aim-icon-center">
                                <div className="aim-icon">
                                    <Image src={Diet} alt="Diet" title="Diet" />
                                </div>
                            </div>
                            <div className="aim-content">
                                <h4 className="sita-font">Provide Basic Essentials</h4>
                                <p>The Foundation activities cater to Food, Clothing, Shelter, Financial aid, etc.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <div className="aim-card">
                            <div className="text-center aim-icon-center">
                                <div className="aim-icon">
                                    <Image src={Education} alt="Education" title="Education" />
                                </div>
                            </div>
                            <div className="aim-content">
                                <h4 className="sita-font">Educational Support</h4>
                                <p>The Trust will extend educational support to the Veer Families.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <div className="aim-card">
                            <div className="text-center aim-icon-center">
                                <div className="aim-icon">
                                    <Image src={Rupee} alt="Rupee" title="Rupee" />
                                </div>
                            </div>
                            <div className="aim-content">
                                <h4 className="sita-font">Financial Support</h4>
                                <p>The Trust will extend financial aid in the form of Cash/ Kind to the Bravehearts and their Families</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="3" lg="3" sm="3" xs="12">
                        <div className="aim-card">
                            <div className="text-center aim-icon-center">
                                <div className="aim-icon">
                                    <Image src={HealthCare} alt="HealthCare" title="HealthCare" />
                                </div>
                            </div>
                            <div className="aim-content">
                                <h4 className="sita-font">Medical Support</h4>
                                <p>Medical Aid to the Servicemen, Ex-servicemen, in the form of treatment, medical devices, hospitalization etc.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="text-center">
                    <div className="button mt-5">
                        <Link to={process.env.PUBLIC_URL+'/support'} className="btn btn-secondary">DONATE FOR A CAUSE</Link>
                    </div>
                </div>
            </Container>
        </section>
    )
}