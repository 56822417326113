import axios from "axios"

export function ContactSubmission(data){
    return new Promise((resolve,reject)=>{
        return axios.post(`${process.env.PUBLIC_URL}/apis/contact-data.php`,{
            name: data.name,
            email: data.email,
            phNo: data.mobileNo,
            message: data.message
        }).then((res)=>{
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}