import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function TrustIntent() {
    return(
        <section className="trust-itent pt-80 pb-80">
            <Container>
                <Row>
                    <Col md="12" lg="12" sm="12" xs="12">
                        <h4 className="section-title">Intent of the TRUST</h4>
                        <p>The Trust is established purely for extending a helping hand and being there for the Bravehearts and their families who have sacrificed their Whole and Soul to protect Us and our Nation.</p>
                        <p>The Trust will not carry out any activities with the intention of earning profit and will perform with service motive only.</p>
                        <p>The Trust is established for the benefit of citizens of India and the class of people mentioned above without discrimination of caste, religion, creed or sex.</p>
                        <div className="text-center">
                            <div className="button mt-5">
                                <Link to={process.env.PUBLIC_URL+'/support'} className="btn btn-secondary">CONTRIBUTE YOUR WAY</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}